import { useState, useReducer, useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { reducer } from "Modules";
import { EventContext, UserContext, AnalyticsContext } from "context";
import { ButtonPrimary } from "Components/Buttons";
import { handleErrors, Field } from "Components/Form";

export const RegistrationForm = ({
  formPart,
  formHeading,
  formBody,
  formLegal,
  submitButton,
  setIneligible,
}) => {
  const { event } = useContext(EventContext);
  const { writeToUserSubmissionDoc } = useContext(UserContext);
  const { logLeadSubmission } = useContext(AnalyticsContext);

  const [registration, setRegistration] = useReducer(reducer, {});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const fields = event.register.fields;

  const eligibleZipCodes = JSON.parse(event.register.zipCodes);
  const zips = eligibleZipCodes.map((item) => item.zip);

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorObject = handleErrors(fields, registration, formPart);

    if (!errorObject) {
      setSubmitting(true);

      if (formPart === 0 && !!zips.length) {
        // check if zip code is eligible
        const zipCodeFound = zips.find(
          (zip) => zip === parseInt(registration.ZIP)
        );

        if (!zipCodeFound) return setIneligible(true);
      }

      registration.formPart = formPart;
      await writeToUserSubmissionDoc(registration);
      logLeadSubmission({ formPart, registration });
    } else {
      setSubmitting(false);
      setErrors(errorObject);
    }
  };

  return (
    <Wrapper>
      <img
        src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy1.gif"
        style={{
          position: "absolute",
          right: "-10%",
          top: "0%",
          width: 90,
        }}
        alt="animated flower"
      />
      {formHeading && <FormTitle>{ReactHtmlParser(formHeading)}</FormTitle>}
      {formBody && <FormBody>{ReactHtmlParser(formBody)}</FormBody>}
      <img
        src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy2.gif"
        style={{
          position: "absolute",
          left: "-25%",
          bottom: "45%",
          width: 90,
        }}
        alt="animated flower"
      />

      {fields && (
        <RegForm onSubmit={handleSubmit}>
          <Form>
            {fields
              .sort((a, b) => a.order - b.order)
              .filter((field) => {
                return field.formPart === formPart;
              })
              .map((field, index) => {
                let mobileSize = "100%";
                let desktopSize = "100%";
                if (field.span === "half") desktopSize = "calc(50% - 4px)";

                if (
                  field.span !== "full" &&
                  (field.spanMobile === "half" || !field.spanMobile)
                )
                  mobileSize = "calc(50% - 4px)";

                return (
                  <FieldContainer
                    sizeDesktop={desktopSize}
                    sizeMobile={mobileSize}
                    fullSize={field.span === "full"}
                    key={index}
                    order={field.order}
                  >
                    {field.type !== "optIn" && !!field.label && (
                      <Label>{field.label}</Label>
                    )}
                    <Field
                      field={field}
                      value={registration[field.name]}
                      onChange={(value) => handleChange(value, field.name)}
                      error={{
                        status: errors[field.name],
                        message: errors[field.name]?.message,
                      }}
                    />
                  </FieldContainer>
                );
              })}
            <FormLegal>
              By providing your information, you are directing Music Audience
              Exchange to share your name, email address, phone number and zip
              code with McDonald’s for use as described in{" "}
              <a
                href="https://www.mcdonalds.com/us/en-us/privacy.html"
                rel="noreferrer"
                target="_blank"
              >
                McDonald's Privacy Policy
              </a>
              . The collection and use of any information collected is otherwise
              subject to{" "}
              <a
                href="https://www.max.live/privacy-policy/"
                rel="noreferrer"
                target="_blank"
              >
                Music Audience Exchange’s Privacy Policy
              </a>
              .
            </FormLegal>
            {formLegal && <FormLegal>{ReactHtmlParser(formLegal)}</FormLegal>}
          </Form>

          {submitting ? (
            <RegisterButton>Loading</RegisterButton>
          ) : (
            <RegisterButton type="submit">
              {submitButton ? submitButton : "Submit"}
            </RegisterButton>
          )}
        </RegForm>
      )}
    </Wrapper>
  );
};

const FormTitle = styled.h2`
  font-size: 60px;
  margin: 12px auto 12px 0;
  @media (max-width: 950px) {
    font-size: 45px;
  }
`;
const FormBody = styled.p`
  margin: 0px auto 25px 0;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 24px;
  margin: 12px 0 20px;
  width: 100%;
`;

const RegForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: -15px;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 550px;
  padding: 0 30px;
  position: relative;
  @media (max-width: 950px) {
    padding: 0;
  }
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 ${(props) => props.sizeMobile};
  ${(props) => props.order && `order: ${props.order};`}
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 0 1 ${(props) => props.sizeDesktop};
  }
`;

const Label = styled.span`
  color: #4d4d4d;
  font-size: 11px;
  margin-bottom: 5px;
`;

const FormLegal = styled.div`
  font-size: 11px;
  margin: 0px;
  order: 99;

  a,
  a:visited {
  }
`;

const RegisterButton = styled(ButtonPrimary)``;
