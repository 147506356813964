import styled from "styled-components";
import { fonts } from "theme/fonts";

export const ButtonPrimary = ({
  as,
  href,
  className,
  onClick,
  target,
  rel,
  children,
}) => {
  return (
    <Wrapper
      as={as}
      href={href}
      className={className}
      onClick={onClick}
      target={target}
      rel={rel}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  height: 50px;
  /* padding-bottom: 9px;
  padding-top: 13px; */
  border: 0px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  font-family: "Speedee_Reg";
  margin-right: auto;
  &:hover {
  }
  @media (max-width: 950px) {
    width: 100%;
    margin: auto;
  }
`;
