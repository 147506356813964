import { useContext } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";
import { PreCampaign } from "Routes/Root/PreCampaign";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  preCampaign,
  postCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event?.meta?.storageDirectory;

  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/o/${storageDirectory}%2F${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    heading,
    subHeading,
    headingRegistered,
    subHeadingRegistered,
    headingPostCampaign,
    maxPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };
  console.log("brans", brandDisclaimer, event.footer, sweepsDisclaimer);
  return (
    <>
      {showFooter && (
        <FooterContainer preCampaign={preCampaign}>
          {!preCampaign && (
            <img
              src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/ripped.jpg"
              style={{
                width: "100%",
                objectFit: "cover",
                margin: "-10px 0 35px 0",
              }}
              alt="ripped paper pattern"
            />
          )}
          {!preCampaign && brandDisclaimer && (
            <MaxPolicy>{ReactHtmlParser(brandDisclaimer)}</MaxPolicy>
          )}
          <MaxPolicy>{ReactHtmlParser(maxPolicy)}</MaxPolicy>
          <MaxPolicy>
            <CookieButton
              onClick={() => {
                if (window.Cookiebot) {
                  window.Cookiebot.renew();
                }
              }}
            >
              Click Here to set Cookie Preferences.
            </CookieButton>
          </MaxPolicy>
        </FooterContainer>
      )}
    </>
  );
};

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 1500px;
  position: relative;
  ${({ preCampaign }) =>
    preCampaign &&
    css`
      justify-content: flex-end;
    `}
`;

const MaxPolicy = styled.p`
  text-align: center;
  font-size: 16px;
  color: black;
  margin-top: 0px;
  font-family: "Speedee_RegCd";
  padding: 0 20px;

  a {
    color: black;
  }

  @media (max-width: 950px) {
    font-size: 16px;
  }
`;

const BrandDisclaimer = styled.div`
  text-align: center;
  margin-top: 6em;
  font-size: 0.6rem;

  @media (max-width: 950px) {
    font-size: 0.6rem;
    margin-bottom: -10px;
  }
`;
export const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #000;
  display: inline;
  font-size: 16px;
  font-family: "Speedee_RegCd";
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;
