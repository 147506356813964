import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext, TempContext } from "context";
import { PrimaryTemplate as Layout } from "Layout/Templates";
import { MaxWidthContainer } from "Layout/MaxWidthContainer";
import {
  Modal,
  Media16By9,
  MediaVideo,
  HeroVideo,
  ButtonPrimary,
} from "Components";
import { RegistrationForm } from "Components/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "Components/Assets/logo.png";
import heroImg from "Components/Assets/heroImg.png";
import { useMediaQuery } from "hooks";
import { VideoPlayer } from "Components";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  motion,
  useInView,
  useAnimation,
} from "framer-motion/dist/framer-motion";
import { AnimatePop } from "./AnimatePop";
import { AnimateSlideRight } from "./AnimateSlideRight";
import { AnimateSlideLeft } from "./AnimateSlideLeft";
import { AnimateRotate } from "./AnimateRotate";
import { CookieButton } from "Layout";

export const RegistrationLayout = React.memo(
  ({
    sweeps,
    postSweeps,
    ineligible,
    formStep = 0,
    registered,
    postCampaign,
    setIneligible,
  }) => {
    const { event } = useContext(EventContext);
    const { logVideoAction, logClick } = useContext(AnalyticsContext);

    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState(null);
    const [loaded, setLoaded] = useState(false);

    const setModalVisible = (bool, link) => {
      setVideo(link);
      setOpen(bool);
      // logVideoAction({ bool, videoLink: link, videoTitle: "Hero Video" });
    };
    const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
    const storageDirectory = event?.meta?.storageDirectory;

    const isMobile = useMediaQuery();
    // logo
    const { brand: brandLogo, hero, link } = event?.logos;

    const scrollToSection = (elementRef) => {
      elementRef.current.scrollIntoView();
    };

    const {
      footerHeading,
      maxPolicy,
      brandDisclaimer,
      downloadLink,
      downloadLinkSp,
      privacyPolicy,
      MAXpage,
    } = event.footer;

    if (registered) formStep = event?.register.sweeps.length - 1;
    // registration content
    const {
      heading,
      subHeading,
      subHeadingPostReg,
      subBody,
      body,
      bodyPostSweep,
      bodyPostReg,
      formHeading,
      formBody,
      formLegal,
      submit,
      pageCheck,
    } = sweeps
      ? event?.register.sweeps[formStep]
      : event?.register.postSweeps[formStep];

    // ineligible content
    const {
      heading: ineligibleHeading,
      body: ineligibleBody,
    } = event?.register;

    // music video background
    const {
      background: musicVideoBg,
      label: musicVideoLabel,
      video: musicVideoLink,
    } = event?.callToActions.musicVideo;
    let videoBgDesktop = `${api}/o/${storageDirectory}%2F${musicVideoBg.desktop}?alt=media`;
    let videoBgMobile = `${api}/o/${storageDirectory}%2F${musicVideoBg.mobile}?alt=media`;

    // brand cta
    const {
      btnLink: brandCTALink,
      btnText: brandCTAText,
      heading: brandCTAHeading,
    } = event?.callToActions.brand;

    // brand cta background
    const {
      desktop: brandBgD,
      mobile: brandBgM,
    } = event?.callToActions.brand.background;
    let brandBgDesktop = `${api}/o/${storageDirectory}%2F${brandBgD}?alt=media`;
    let brandBgMobile = `${api}/o/${storageDirectory}%2F${brandBgM}?alt=media`;
    console.log(link);
    // brand product background
    const {
      desktop: brandProductDesktop,
      mobile: brandProductMobile,
    } = event?.callToActions.brand.product;
    let productDesktop = `${api}/o/${storageDirectory}%2F${brandProductDesktop}?alt=media`;
    let productMobile = `${api}/o/${storageDirectory}%2F${brandProductMobile}?alt=media`;

    // post-campaign content
    const {
      heading: postCampaignHeading,
      headingMobile: postCampaignHeadingMobile,
      subHeading: postCampaignSubHeading,
      body: postCampaignBody,
      heroImage,
    } = event?.postCampaign;
    let heroImg = `${api}/${storageDirectory}/${heroImage}?alt=media`;

    // brand cta (post-campaign)
    const {
      btnLink: brandSecondaryCTALink,
      btnText: brandSecondaryCTAText,
      heading: brandSecondaryCTAHeading,
    } = event?.callToActions.brandSecondary;

    const { video: heroVideo } = event?.callToActions?.heroVideo;
    // const desktopVideoUrls = "https://storage.googleapis.com/whitelabel-set-live.appspot.com/632-mcdonalds-los-parras/desktop/manifest.m3u8"
    // const desktopVideoUrls = desktop?.map(
    //   (url) => `${api}/${storageDirectory}/${url}`
    // );
    //  const desktopVideoUrls = `${api}/${storageDirectory}/${desktop[0]}`;
    //  const mobileVideoUrls = `${api}/${storageDirectory}/${mobile[0]}`;

    //   console.log("URL",desktopVideoUrls)
    // const mobileVideoUrls = mobile?.map(
    //   (url) => `${api}/o/${storageDirectory}%2F${url}?alt=media`
    // );

    //  const currentVideoUrl = isMobile ? desktopVideoUrls : mobileVideoUrls;

    const linkClickHandler = (url, label, category) => {
      logClick({ label, url }, category);
    };

    return (
      <Layout
        registration={postCampaign ? false : true}
        flexGrow
        showFooter
        registered={registered}
        postCampaign={postCampaign}
        preCampaign={false}
      >
        <Container>
          <HeroContent>
            <LogoLink
              onClick={() => {
                window.open(brandLogo?.link, "_blank");
                linkClickHandler(
                  brandLogo?.link,
                  "McDonalds Homepage",
                  "header"
                );
              }}
            />
            <ArtistLeft>
              <HeroImg
                onLoad={() => setLoaded(true)}
                isMobile={isMobile}
                src={
                  isMobile
                    ? "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/artistLeftMob.jpg"
                    : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/heroLeft.jpg"
                }
              />
            </ArtistLeft>

            <ArtistRight>
              <HeroImg
                onLoad={() => setLoaded(true)}
                isMobile={isMobile}
                src={
                  isMobile
                    ? "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/artistRightMob.jpg"
                    : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/heroRight.jpg"
                }
              />
            </ArtistRight>
            <LogoLink
              play={true}
              onClick={() => {
                setModalVisible(true, link);
                linkClickHandler(link, "Hero Video", "header");
              }}
            />
          </HeroContent>

          <Content reg={!registered && !postCampaign}>
            {!registered && !postCampaign && (
              <>
                <RegistrationForm
                  formPart={formStep}
                  formHeading={formHeading}
                  formBody={formBody}
                  formLegal={formLegal}
                  // formPolicy={formPolicy}
                  submitButton={submit}
                  setIneligible={setIneligible}
                />
                <div style={{ position: "relative" }}>
                  <img
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy2.gif"
                    style={{
                      position: "absolute",
                      right: "-10%",
                      top: !isMobile && "40%",
                      bottom: isMobile && "-4%",
                      width: 90,
                      transform: "rotate(-90deg)",
                    }}
                    alt="animated flower"
                  />
                  <img
                    style={{
                      width: "100%",
                      margin: !isMobile && "-160px auto 10px auto",
                      zIndex: 20,
                      maxWidth: 500,
                    }}
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/asideReg.png"
                    alt="polaroid style images of frozen McDondals drinks"
                  />
                  <img
                    src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy3.gif"
                    style={{
                      position: "absolute",
                      right: !isMobile && 30,
                      left: isMobile && "-10%",
                      bottom: isMobile ? "60%" : "5%",
                      width: 70,
                    }}
                    alt="animated flower"
                  />
                </div>
              </>
            )}

            {(registered || postCampaign) && (
              <>
                <Wrapper>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      maxWidth: isMobile && "600px",
                      position: "relative",
                    }}
                  >
                    <img
                      src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy1.gif"
                      style={{
                        position: "absolute",
                        right: 0,
                        top: isMobile ? "-10%" : "10%",
                        width: isMobile ? 80 : 120,
                      }}
                      alt="animated flower"
                    />
                    {(heading || postCampaignHeading) && (
                      <Heading>
                        {ReactHtmlParser(
                          postCampaign ? postCampaignHeading : heading
                        )}
                      </Heading>
                    )}
                    {(body || postCampaignBody) && (
                      <Body>
                        {ReactHtmlParser(
                          postCampaign ? postCampaignBody : body
                        )}
                      </Body>
                    )}
                    <img
                      src="https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy2.gif"
                      style={{
                        position: "absolute",
                        left: "-120px",
                        bottom: "-120px",
                        width: 120,
                      }}
                      alt="animated flower"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        margin: isMobile ? "30px 0" : "-200px 100px 100px auto",
                        zIndex: 20,
                        maxWidth: 550,
                        transform: isMobile && "rotate(-25deg) scale(1.2)",
                      }}
                      src={
                        postCampaign
                          ? "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/postCampRight.png"
                          : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/postRegRight.png"
                      }
                      alt="polaroid style images of frozen McDondals drinks"
                    />
                  </div>
                </Wrapper>
                <Wrapper reverse={true}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      marginRight: 50,
                      position: "relative",
                    }}
                  >
                    <img
                      src={`https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy${
                        isMobile ? "2" : "3"
                      }.gif`}
                      style={{
                        position: "absolute",
                        left: !isMobile && "-10px",
                        right: isMobile && "-25%",
                        bottom: "10px",
                        width: 80,
                      }}
                      alt="animated flower"
                    />
                    <img
                      style={{
                        width: "100%",
                        margin: isMobile
                          ? "50px 0 20px"
                          : "-30px 30px 30px auto",
                        zIndex: 20,
                        maxWidth: 450,
                        transform: isMobile && "rotate(15deg) scale(1.2)",
                      }}
                      src={
                        postCampaign
                          ? "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/postCampLeft.png"
                          : "https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/postRegLeft.png"
                      }
                      alt="polaroid style images of frozen McDondals drinks"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      maxWidth: isMobile && "600px",
                      position: "relative",
                    }}
                  >
                    <img
                      src={`https://storage.googleapis.com/whitelabel-set-live.appspot.com/679-mcdonalds-gayle/giphy${
                        isMobile ? "3" : "2"
                      }.gif`}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "10%",
                        width: isMobile ? 60 : 120,
                        transform: "rotate(-90deg)",
                      }}
                      alt="animated flower"
                    />
                    {(subHeading || postCampaignSubHeading) && (
                      <Heading>
                        {ReactHtmlParser(
                          postCampaign ? postCampaignSubHeading : subHeading
                        )}
                      </Heading>
                    )}
                    {subBody && <Body>{ReactHtmlParser(subBody)}</Body>}
                    <StyledButton
                      download={true}
                      onClick={() => {
                        window.open(
                          "https://www.mcdonalds.com/us/en-us/download-app.html",
                          "_blank"
                        );
                        linkClickHandler(
                          "https://www.mcdonalds.com/us/en-us/download-app.html",
                          "Download app",
                          "external_link"
                        );
                      }}
                    >
                      Download the App
                    </StyledButton>
                    <Body sm={true}>
                      Download the McDonald’s app and join rewards to earn
                      points toward free food. At participating McDonald’s. Must
                      opt into Rewards. ©2023 McDonald’s
                    </Body>
                  </div>
                </Wrapper>
              </>
            )}
          </Content>
        </Container>

        <Modal open={open} setOpen={setModalVisible}>
          <Media16By9 play="true">
            <MediaVideo
              src={video}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </Media16By9>
        </Modal>
      </Layout>
    );
  }
);

const Container = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  z-index: 2;
  max-width: 1500px;

  @media (max-width: 950px) {
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: #ffbc0d;
  padding: 30px;
  z-index: 20;
  width: 100%;
  ${({ reg }) =>
    reg &&
    css`
      flex-direction: row;
    `}

  @media (max-width: 950px) {
    overflow: hidden;
    flex-direction: column;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 0 50px;
  ${({ reverse }) => reverse && css``}
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: column-reverse;
        align-items: center;
      `}
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: -5px;
  width: 100%;
  position: relative;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;
const ArtistLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ArtistRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const HeroImg = styled.img`
  width: 100%;
  object-fit: cover;
  z-index: 40;
  @media (max-width: 950px) {
  }
`;
const LogoLink = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;
  z-index: 50;
  max-width: 300px;
  cursor: pointer;
  ${({ play }) =>
    play &&
    css`
      left: unset;
      right: 0;
      height: 40%;
      max-height: 300px;
    `}
`;

const VideoContainer = styled(VideoPlayer)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;

  video {
    object-fit: cover;
  }
`;

const StyledLink = styled.a`
  max-width: 100px;

  @media (max-width: 950px) {
    max-width: 50px;
  }
`;

const StyledButton = styled(ButtonPrimary)`
  width: 40%;
  max-width: 350px;
  height: 45px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 7px 1px 12px 1px; */
  margin: 0px auto 0;
  border-radius: 3px;

  ${(props) =>
    props.download && "width: 270px; margin: 20px 0 0; align-self: center"}

  @media (max-width: 950px) {
    font-size: 1rem;

    width: 270px;
  }

  & > a {
    color: black;
    text-decoration: none;
  }
`;

const Heading = styled.h2`
  font-size: min(3.5vw, 60px);
  margin-bottom: 10px;

  @media (max-width: 950px) {
    font-size: 40px;
  }
`;

const Body = styled.p`
  color: black;
  font-size: 21px;
  line-height: 140%;
  display: block;
  width: 70%;
  text-align: left;
  ${({ sm }) =>
    sm &&
    css`
      margin-top: 30px;
      font-size: 15px;
    `}

  @media (max-width: 950px) {
    font-size: 18px;
    line-height: 150%;
    width: 100%;
    ${({ sm }) =>
      sm &&
      css`
        margin-top: 30px;
        font-size: 13px;
      `}

    div {
      font-size: 1rem;
      text-decoration: underline;
    }
  }

  & > span {
    display: inline-block;
    font-size: 1.4rem;

    @media (max-width: 950px) {
      font-size: 1rem;
    }
  }
`;
